(function ($) {
    //order form
    $(".orders, .testdrive").each(function () {


        var stickyEl = new Sticksy('.sticky', {
            topSpacing: 0,
        })
        stickyEl.onStateChanged = function (state) {
            if (state === 'fixed') stickyEl.nodeRef.classList.add('a')
            else stickyEl.nodeRef.classList.remove('b')
        }

    })

})(jQuery);