(function ($) {
    // <script>window.CI360.init();</script>
    var t = document.querySelectorAll(".cloudimage-360:is(.initialized)");
    if(!(t && t.length > 0)){
        window.CI360.init();
    }

    $(".switch-container button").on("click", function (e) {
        console.log("---start---")

        var t = e.currentTarget,
            folder = t.getAttribute("data-src");
        v = window.CI360._viewers[0],
            container = v.container,
            amount = v.amount, //图片张数
            lazyload = false,
            lazySelector = 'lazyload',
            filename = container.getAttribute("data-filename"),
            responsive = false;
        //console.log(v.imageList)
        v.folder = folder;
        src = folder + filename
        // console.log(v)
        // v.images = [];
        var active = v.activeImage -1;  //0-11
        //  v.activeImage = 1;
        //v.preloadImages(amount, src, lazyload, lazySelector, container, responsive, {});
        /* for (i = 0; i< amount; i++) {
             //第一张图片，设置为当前的角度的地址
             var image = v.images[i];

             if (active < amount) {
                 active++;
             } else {
                 active = 1
             }
             var resultSrc = src.replace('{index}', active);
             image.src=resultSrc;
             console.log(image)
           //  console.log(image.src)
         }*/
        $(t).addClass('active');
        $(t).siblings().removeClass("active")
        v.images = [];
        for (i =0; i < amount;i++) {
            var image = new Image();
            var resultSrc = src.replace('{index}', i+1);
            image.src=resultSrc;
            v.images.push(image);

            if (i == active) {
                image.onload = function(e){
                    v.update();
                }
            }

        }
    })

})(jQuery);