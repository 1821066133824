/* magnificPopup img view */

$('.js--trigger-image-popup').magnificPopup({

    type: 'image',

    gallery: {

        enabled: true

    }

});