(function ($) {
    const  etp3_fd_en = ['ETP3','360&deg;','EXTERIOR','INTERIOR','INTERIOR','CORE TECH','BATTERY','RANGE','CHARGING','SPECS'];
    const etm6_fd_en = ['ETM6','360&deg;','EXTERIOR','EXTERIOR','INTERIOR','INTERIOR','CORE TECH','e-PLATFORM','APPLICATIONS','BATTERY','RANGE','CHARGING','ADAS','SERENITY','SPECS'];
    const eth8_fd_en = ['ETH8','360&deg;','EXTERIOR','INTERIOR','INTERIOR','CORE TECH','e-PLATFORM','APPLICATIONS','BATTERY','RANGE','CHARGING','ADAS','SERENITY','SPECS'];
    const  eyh_fd_en = ['EYT','360&deg;','EXTERIOR','INTERIOR','CORE TECH','BATTERY',' CHARGING','APPLICATIONS','SPECS'];
    const  etp3_fd_nl = ['ETP3','360&deg;','exterieur','interieur','interieur','core-technologie','batterij',' actieradius','opladen','specificaties'];
    const etm6_fd_nl = ['ETM6','360&deg;','exterieur','exterieur','interieur','interieur','core-technologie','e-Platform','batterij','bereik','opladen','ADAS','sereniteit','specificaties'];
    const eth8_fd_nl = ['ETH8','360&deg;','exterieur','interieur','interieur','core-technologie','e-Platform','toepassingen','batterij','bereik','opladen','ADAS','sereniteit','specificaties'];
    const  eyh_fd_nl = ['EYT','360&deg;','exterieur','interieur','core-technologie','batterij','opladen','toepassingen','specificaties'];
    const  etp3_fd_gr = ['ETP3','360&deg;','ΕΞΩΤΕΡΙΚΟ','ΕΣΩΤΕΡΙΚΟ','ΕΣΩΤΕΡΙΚΟ','ΘΕΜΕΛΙΩΔΗΣ ΤΕΧΝΟΛΟΓΙΑ','ΜΠΑΤΑΡΙΑ',' ΑΚΤΙΝΑ ΔΡΑΣΗΣ','ΦΟΡΤΙΣΗ','ΠΡΟΔΙΑΓΡΑΦΕΣ'];
    const etm6_fd_gr = ['ETM6','360&deg;','ΕΞΩΤΕΡΙΚΟ','ΕΞΩΤΕΡΙΚΟ','ΕΣΩΤΕΡΙΚΟ','ΕΣΩΤΕΡΙΚΟ','ΘΕΜΕΛΙΩΔΗΣ ΤΕΧΝΟΛΟΓΙΑ','ΗΛΕΚΤΡΟΝΙΚΗ ΠΛΑΤΦΟΡΜΑ (e-PLATFORM)','ΕΦΑΡΜΟΓΕΣ','ΜΠΑΤΑΡΙΑ','ΑΚΤΙΝΑ ΔΡΑΣΗΣ','ADAS','ΓΑΛΗΝΗ','ΠΡΟΔΙΑΓΡΑΦΕΣ'];
    const eth8_fd_gr = ['ETH8','360&deg;','ΕΞΩΤΕΡΙΚΟ','ΕΣΩΤΕΡΙΚΟ','ΕΣΩΤΕΡΙΚΟ','ΘΕΜΕΛΙΩΔΗΣ ΤΕΧΝΟΛΟΓΙΑ','ΗΛΕΚΤΡΟΝΙΚΗ ΠΛΑΤΦΟΡΜΑ (e-PLATFORM)','ΕΦΑΡΜΟΓΕΣ','ΜΠΑΤΑΡΙΑ','ΑΚΤΙΝΑ ΔΡΑΣΗΣ','ΦΟΡΤΙΣΗ','ADAS','ΓΑΛΗΝΗ','ΠΡΟΔΙΑΓΡΑΦΕΣ'];
    const  eyh_fd_gr = ['EYT','360&deg;','ΕΞΩΤΕΡΙΚΟ','ΕΣΩΤΕΡΙΚΟ','ΘΕΜΕΛΙΩΔΗΣ ΤΕΧΝΟΛΟΓΙΑ','ΜΠΑΤΑΡΙΑ',' ΦΟΡΤΙΣΗ','ΕΦΑΡΜΟΓΕΣ','ΠΡΟΔΙΑΓΡΑΦΕΣ'];
    const  etp3_fd_no = ['ETP3','360&deg;','EKSTERIØR','INTERIØR','INTERIØR','KJERNETEKNOLOGI','BATTERI',' REKKEVIDDE','LADING','SPESIFIKASJONER'];
    const etm6_fd_no = ['ETM6','360&deg;','EKSTERIØR','EKSTERIØR','INTERIØR','INTERIØR','KJERNETEKNOLOGI','e-PLATFORM','APPLIKASJONER','BATTERI','REKKEVIDDE','LADING','ADAS','STILLHET','SPESIFIKASJONER'];
    const eth8_fd_no = ['ETH8','360&deg;','EKSTERIØR','INTERIØR','INTERIØR','KJERNETEKNOLOGI','e-PLATFORM','APPLIKASJONER','BATTERI','REKKEVIDDE','LADING','ADAS','STILLHET','SPESIFIKASJONER'];
    const  eyh_fd_no = ['EYT','360&deg;','EKSTERIØR','INTERIØR','KJERNETEKNOLOGI','BATTERI','LADING','APPLIKASJONER','SPESIFIKASJONER'];
    if (window.innerWidth > 1199 && (window.devicePixelRatio == 1 || window.devicePixelRatio == 2)){
        let fd_navigationTooltips ;
        if($('#fullpage').attr('class')!=undefined){
            if($('#fullpage').attr('class').indexOf('etp3')>-1){
                switch ($('.sub-menu__item__language span').html()){
                    case "NL":
                        fd_navigationTooltips = etp3_fd_nl;
                        break
                    case "GR":
                        fd_navigationTooltips = etp3_fd_gr;
                        break
                    case "NB":
                        fd_navigationTooltips = etp3_fd_no;
                        break
                    default:
                        fd_navigationTooltips = etp3_fd_en;
                }
            }
            if($('#fullpage').attr('class').indexOf('etm6')>-1){

                switch ($('.sub-menu__item__language span').html()){
                    case "NL":
                        fd_navigationTooltips = etm6_fd_nl;  break;
                    case "GR":
                        fd_navigationTooltips = etm6_fd_gr;break;
                    case "NB":
                        fd_navigationTooltips = etm6_fd_no;break;
                    default:
                        fd_navigationTooltips = etm6_fd_en;
                }
            }
            if($('#fullpage').attr('class').indexOf('eth8')>-1){

                switch ($('.sub-menu__item__language span').html()){
                    case "NL":
                        fd_navigationTooltips = eth8_fd_nl;  break;
                    case "GR":
                        fd_navigationTooltips = eth8_fd_gr;break;
                    case "NB":
                        fd_navigationTooltips = eth8_fd_no;break;
                    default:
                        fd_navigationTooltips = eth8_fd_en;
                }
            }
            if($('#fullpage').attr('class').indexOf('eyt')>-1){

                switch ($('.sub-menu__item__language span').html()){
                    case "NL":
                        fd_navigationTooltips = eyh_fd_nl;  break
                    case "GR":
                        fd_navigationTooltips = eyh_fd_gr;break;

                    case "NB":
                        fd_navigationTooltips = eyh_fd_no;break;
                    default:
                        fd_navigationTooltips = eyh_fd_en;
                }
            }
        }
        console.log(fd_navigationTooltips,'=================')
        $('#fullpage').fullpage({
            navigation:true,
            scrollBar:true,
            navigationPosition: 'right',
            showActiveTooltip:true,
            // anchors: ['top'],
            // menu: '#menu',
            navigationTooltips: fd_navigationTooltips,
            onLeave: function(origin, destination, direction, trigger){
                $('.section [data-aos]').each(function(){
                    $(this).removeClass("aos-animate")
                });
                // console.log(origin.index, destination.index,'--------onLeave');

                if(destination.index>0){
                    $('.top-right-div').removeClass('hide');
                }else {
                    $('.top-right-div').addClass('hide');
                }
               let scroll_index_font = $('#fp-nav > ul > li:eq('+destination.index+')').children('div');
               let scroll_index_span= $('#fp-nav > ul > li:eq('+destination.index+')').children('a').children('span').eq(1);
               let origin_index_font = $('#fp-nav > ul > li:eq('+origin.index+')').children('div');
               let origin_index_span =$('#fp-nav > ul > li:eq('+origin.index+')').children('a').children('span').eq(1);
                if($(destination.item).hasClass("s-b")){
                    scroll_index_font.addClass('fp-select-row-color-black');
                    scroll_index_span.addClass('fp-select-row-bagcolor-black');
                    $('.a_top_').css('color','#000');
                    $('.a_top_img').attr('src','dist/images/icons/Vector-top-b.svg')
                }else {
                    scroll_index_font.addClass('fp-select-row-color-white');
                    scroll_index_span.addClass('fp-select-row-bagcolor-white');
                    $('.a_top_').css('color','#fff');
                    $('.a_top_img').attr('src','dist/images/icons/Vector-top-w.svg')
                }
                if(origin_index_span.hasClass('fp-select-row-bagcolor-black')){
                    origin_index_font.removeClass('fp-select-row-color-black');
                    origin_index_span.removeClass('fp-select-row-bagcolor-black');
                }
                if(origin_index_span.hasClass('fp-select-row-bagcolor-white')){
                    origin_index_font.removeClass('fp-select-row-color-white');
                    origin_index_span.removeClass('fp-select-row-bagcolor-white');
                }

            },
            afterLoad: function(origin, destination, direction ,trigger){
                // console.log(origin, destination, direction ,trigger,'--------afterLoad');
                $('.section.active [data-aos]').each(function(){
                    $(this).addClass("aos-animate")
                });
            }

        });
        $('#top_a_click').bind('click', function(e){
            e.preventDefault();
            $('#fullpage').fullpage.moveTo(1);
        });
    }else {
        $('section').removeClass('section99');
        $('.section-content-18 .roll-warp .banner-wrap').css('height','auto');
    }

})(jQuery);



