(function ($) {

    //使用bootstrap form valid
    /*window.addEventListener('load', function() {
        // Fetch all the forms we want to apply custom Bootstrap validation styles to
        var forms = document.getElementsByClassName('needs-validation');
        // Loop over them and prevent submission

        var validation = Array.prototype.filter.call(forms, function(form) {
            form.addEventListener('submit', function(event) {
                //form.checkValidity browser default api
                console.log("-------", form.checkValidity())
                if (form.checkValidity() === false) {
                    event.preventDefault();
                    event.stopPropagation();
                }
                form.classList.add('was-validated');
            }, false);
        });
    }, false);*/

    /* $(".subscribe-btn").on("click", function (e) {
         e.preventDefault();
         $(this).addClass("loading")
        // successDialog()
     })*/



    $(".news-letter__form").on("submit", function (e) {
        e.preventDefault();

        var self = $(this), url = self.attr("action");

        if (this.checkValidity() === false) {
            this.classList.add("was-validated")
            return
        }

        var btn = self.find("button");
        // btn.addClass("loading")


        var loadDialog = showLoading();


        var email = self.find("input[name='email']");
        $.ajax({
            url: url,
            method: "post",
            data: self.serialize(),
            success: function (result) {
                loadDialog.Modal("hide")
                var success = result.success,
                    data = result.data;
                if (success) {
                    successDialog(data)
                }

            },
            complete: function (result) {
                // console.log(result.status)
                if (result.status == "404") {
                    errorDialog(result.status, "Page not found")
                } else if (result.status == "500") {
                    errorDialog(result.status, result.statusText)
                }
                console.log(result)
            }
        })

    });
    $(".agree input").on("change", function () {
        var v = $(this).is(":checked");
        var btn = $(this).parents("form").find("button.btn-submit");

        if (v) {
            btn.prop('disabled', false).removeClass("disabled")
        } else {
            btn.prop('disabled', true).addClass("disabled")
        }
    })

    $('.ajax-form').on("submit", function (e) {
        e.preventDefault();
        var self = $(this), url = self.attr("action");
        if (this.checkValidity() === false) {
            this.classList.add("was-validated");
            return
        }
        var loadDialog = showLoading();
        $.ajax({
            url: url,
            method: "post",
            data: self.serialize(),
            success: function (result) {
                loadDialog.Modal("hide")
                console.log(result)
                var success = result.success,
                    data = result.data;
                if (success) {
                    successDialog(data)
                }

            },
            complete: function (result) {
                // console.log(result.status)
                if (result.status == "404") {
                    errorDialog(result.status, "Page not found")
                } else if (result.status == "500") {
                    errorDialog(result.status, result.statusText)
                }
                console.log(result)
            }
        })
    });
})(jQuery);

function showLoading() {
    var $content = $('<div class="dialog loading-dialog">' +
        '  <div class="spinner-border" role="status"></div>\n' +
        '  <div>Submitting...</div>' +
        '</div>');
    $content.Modal({
        //  width: "1200px",
        // height: "600px",
        hideCloseBtn: true,
        blankClickClose: false,
        // darkMode: true,
        autoSize: true,
    });
    return $content;
}

function errorDialog(code, msg) {
    var $content = $('<div class="dialog error-dialog">' +
        '<div>' +
        '<div class="code">' + code + '</div>' +
        '<div class="message">' + msg + '</div>' +
        '</div>' +
        '</div>');

    $content.Modal({
        //  width: "1200px",
        // height: "600px",
        hideCloseBtn: false,
        // darkMode: true,
        autoSize: true,
    });
}

function successDialog(data) {
    console.log(data)
    var $content = $('<div class="dialog success-dialog">' +
        '<div>' +
        '<div class="icon"><img src="/dist/images/icons/' + data.icon + '"> </div>' +
        '<div class="title">' + data.title + '</div>' +
        '<div class="message">' + data.content + '</div>' +
        '</div>' +
        '</div>');

    $content.Modal({
        //  width: "1200px",
        // height: "600px",
        hideCloseBtn: false,
        // darkMode: true,
        autoSize: true,
    });
}



