
(function ($) {

    let NAMESPACE = "modal",
        EVENT_CLICK = "click." + NAMESPACE,
        EVENT_KEYUP = "keyup." + NAMESPACE;

    function Modal(element, options) {
        this.$element = element;
        var settings = $.extend({
            hideCloseBtn: false,
            blankClickClose: false, //modal空白处关闭
            width: undefined,
            height:undefined,
            isPage: false,
            darkMode: false, //深色
            autoSize: false,//不指定宽和高，由html确定
        }, options);
        var buttonCls = "btn-close";
        if (settings.hideCloseBtn) {
            buttonCls += " hide"
        }

        this.options = settings;



        var elStyle = [];
        if(settings.width) {
            elStyle.push("width:" + settings.width)
        }
        if(settings.height){
            elStyle.push("height:" + settings.height)
        }
        if(settings.autoSize) {

        }
        var modalCls = ['modal']
        if (settings.isPage) {
            modalCls.push('is-page')
        }
        if(settings.darkMode) {
            modalCls.push('dark-mode')
        }
        if(settings.autoSize) {
            modalCls.push('auto-size')
        }

        modalCls = modalCls.join(" ");
        var outer = $("<div class='"+modalCls+"'>" +
            "<div class='modal-content' style='"+elStyle.join(";")+"'>" +
            "<div class='content'>" +
            '' +
            "</div>" +
            '<button class="' + buttonCls + '"></button>' +
            "</div></div>")
        var content = outer.find(".content");

        this.$element.appendTo(content);
        outer.appendTo('body')
        this.el = outer;

        $('html').css("overflow", "hidden");
        this.init();
    }

    Modal.prototype = {
        constructor: Modal,
        init: function () {
            this.show();
        },
        bind: function () {
            this.$element.on(EVENT_CLICK, $.proxy(this.click, this))
            this.el.on(EVENT_CLICK, $.proxy(this.click, this))
            $(document).on(EVENT_KEYUP, $.proxy(this.keyup, this));
            this.el.find(".btn-close").on(EVENT_CLICK, $.proxy(this.click, this))
        },
        unbind: function () {
            this.$element.off(EVENT_CLICK, this.click)
            $(document).off(EVENT_KEYUP, this.keyup);
            this.el.off(EVENT_CLICK, this.click);
            this.el.find(".btn-close").off(EVENT_CLICK, this.click)
        },
        click: function (e) {
            var target = e.target,
                $t = $(target);

            if ($t.hasClass("btn-close")) {
                this.destroy();
                return
            }
            if ($t.data("modal-close")) {
                this.destroy()
                return
            }
            if (this.options.blankClickClose && $t.hasClass("modal")) {
                this.destroy();
                return;
            }

        },
        keyup: function (e) {
            if (e.which === 27) {
                this.destroy();
            }
        },
        show: function () {
            this.bind();
        },
        hide: function () {
            this.unbind();
            this.destroy();
        },
        destroy: function () {
            this.unbind();
            this.$element.remove();
            this.el.remove();
            $('html').css("overflow", "auto");
            this.$element.removeData(NAMESPACE);
        }
    }

    $.fn.Modal = function (options) {
        var $this = $(this);
        var data = $this.data(NAMESPACE);
        if (!data) {
            $this.data(NAMESPACE, (data = new Modal(this, options)));
        }

        if (typeof options === 'string' && $.isFunction((fn = data[options]))) {
            fn.apply(data);
        }
        return this;
    }

})(jQuery);