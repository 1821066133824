(function ($) {

    $(document).ready(function () {

        $("select").each(function (index, el) {
            $el = $(el);
            //   var placeholder = $el.data("placeholder") || "Place select a option";

            $el.select2({
                //     placeholder: placeholder,
                // selectionCssClass:":all:",
                //  minimumResultsForSearch: Infinity  //   data-minimum-results-for-search="Infinity"  隐藏搜索

            });

            $el.hide();
        })

        //order
        $("#orderCountry").each(function (index, el) {
            var $el = $(el),
                countrys = [];
            $.ajax({
                url: "/country/with_dealer",
                method: "get",
                success: function (result) {
                    countrys = result.results;
                    var data = []
                    for (var i = 0; i < countrys.length; i++) {
                        var c = countrys[i];
                        var tmp = $.extend({}, c)
                        tmp.disabled = false;
                        data.push(tmp)
                    }
                    $el.select2({
                        data: data
                    })
                    $el.on("change", function (e) {
                        var v = this.value;
                        for (var i = 0; i < countrys.length; i++) {
                            var c = countrys[i];
                            if (v == c.text) {
                                if (c.disabled) {
                                    $el.addClass("is-invalid").removeClass("is-valid");
                                    $el.parents("form").addClass("was-validated")
                                } else {
                                    $el.removeClass("is-invalid").addClass("is-valid")
                                }
                                break;
                            }
                        }
                    })
                }
            });


        })
        //test drive
        $("#selectCountry").on("change", function (index, el) {
            var optionSelected = $("option:selected", this);
            var valueSelected = this.value;
            var textSelected = optionSelected.text();

            var $city = $("#selectCity");
            $.ajax({
                url: "/dealer/city",
                method: "get",
                data: {
                    country: valueSelected
                },
                success: function (result) {
                    var data = result.results;
                    $city.empty().select2(
                        {
                            data: data,
                            //  minimumResultsForSearch: -1
                        }
                    )
                    //$city.select2("val", ""); 清空
                    $city.val("").trigger("change")
                }
            });
        })
        $("#selectCity").on("change", function (index, el) {
            var valueSelected = this.value;
            var $dealer = $("#selectDealer");
           /* $.ajax({
                url: "/dealer/shop",
                method: "get",
                data: {
                    city: valueSelected
                },
                success: function (result) {
                    var data = result.results;
                    $dealer.empty().select2(
                        {
                            data: data,
                            //  minimumResultsForSearch: -1
                        }
                    )
                    $dealer.val("").trigger("change")
                }
            });*/

        })
    })

})(jQuery);

