(function ($) {
    $(".page-tabs .navigator").click(function (e) {
        e.preventDefault();
        $this = $(this);
        $this.siblings().removeClass("active")
        $this.addClass("active");

        $tabs = $this.parents(".page-tabs");
        $tabs.find(".page-tab").addClass("hide");
        $("#" + $this.data("target")).removeClass('hide')

    })
})(jQuery);