//footer click
(function($) {

    $(".mobile-footer").each(function() {

        var self = $(this);

        var accordion = self.find(".mobile-footer_accordion");

        var items = accordion.find(".mobile-footer_accordion_item");

        function toggle_item(item) {

            var active = item.hasClass("is-active");

            var body = item.find(".mobile-footer_accordion_item_body");

            items.not(item).removeClass("is-active").find(".mobile-footer_accordion_item_body").stop().slideUp();

            body[active ? "slideUp" : "slideDown"]();
            item[active ? "removeClass" : "addClass"]("is-active");

        }

        accordion.find("div.mobile-footer_accordion_item .mobile-footer_accordion_item_header").on("click", function(e) {

            e.preventDefault();

            var self = $(this);

            var item = self.parents(".mobile-footer_accordion_item");

            toggle_item(item);

        });

    });

})(jQuery);

// nav-box
(function ($) {
    var navLi=$(".nav-item");
    navLi.mouseover(function () {
        $(this).find("a").addClass("current");
        $(this).find(".nav-box").stop().slideDown();
    })
    navLi.mouseleave(function(){
        $(this).find("a").removeClass("current");
        $(this).find(".nav-box").stop().slideUp();
    })
})(jQuery);



// (function($) {
//     $('.owl-testimonial').owlCarousel({
//         loop: true,
//         margin: 0,
//         nav: true,
//         responsiveClass: true,
//         autoplay: false,
//         autoplayTimeout: 5000,
//         autoplaySpeed: 1000,
//         autoplayHoverPause: false,
//         responsive: {
//             0: {
//                 items: 1,
//                 nav: false
//             },
//             480: {
//                 items: 1,
//                 nav: false
//             },
//             667: {
//                 items: 1,
//                 nav: true
//             },
//             1000: {
//                 items: 1,
//                 nav: true
//             }
//         }
//     })
// })(jQuery);

(function($) {
    $('.owl-one').owlCarousel({
        loop: false,
        margin: 0,
        nav: false,
        responsiveClass: true,
        autoplay: false,
        autoplayTimeout: 5000,
        autoplaySpeed: 1000,
        autoplayHoverPause: false,
        // navText:false,
        responsive: {
            0: {
                items: 1,
                nav: false
            },
            480: {
                items: 1,
                nav: false
            },
            667: {
                items: 1,
                nav: true
            },
            1000: {
                items: 1,
                nav: true
            }
        }
    })
})(jQuery);

(function ($){
    let clickbtn = $("#content0-btn");
    let p_tontent = $("#content_btn");
    clickbtn.bind('click',function (e){
        e.preventDefault();
        console.log(p_tontent.hasClass("hide"))
        if(p_tontent.hasClass("hide")){
            clickbtn.toggleClass("bag-color");
            p_tontent.removeClass("hide");
        }else {
            clickbtn.removeClass("bag-color");
            p_tontent.addClass("hide");
        }

    })
})(jQuery);


