var cookieVersion = {
    required: "1.0.0",
    analysis: "1.0.0"
}
let CookieSet = {
    required: {
        status: false,
        timestamp: null,
        version: ""
    },
    analysis: {
        status: false,
        version: "",
        timestamp: null,
    }

};
const storageKey = "consents";
(function ($) {
    //showContainer()
    let s = window.localStorage;
    let consents = s.getItem(storageKey);
    if(!consents) {
        showContainer();
        //点击同意全部
        $("#cookie_agree").click(agreeAll);
        $("#cookies_save_argeeall").click(agreeAll);
        $("#show_cookie_setting").click(function () {
            showCookieSettings()
        })

    } else {
        CookieSet = JSON.parse(consents);
        enableFunctionsFromCookie()
    }
    $("#cookies_save_settings").click(saveCookieSettings)



    // let cookieLang = getCookieLang();
    // cookieLang = cookieLang === "en" || cookieLang === "us" ? "en_US" : cookieLang;
    // const lang = cookieLang || getBrowserLang();
    // console.log(lang)
   // console.log(getCookie('locale'))

    // const lang = getBrowserLang();
    // console.log(lang)
    // if(lang=="nl_NL"){
      // var link = document.getElementById('locale_nl-NL');
     // link.click();
    // }
})(jQuery);

//获取cookie中的默认语言
function getCookieLang() {
    let strcookie = document.cookie;
    console.log(strcookie,"cookie");
    let arrcookie = strcookie.split(";");

    let cookieLang = "";
    for (let i = 0; i < arrcookie.length; i++) {
        let arr = arrcookie[i].split("=");
        //当cookie语言相关的键名为：org.springframework.web.servlet.i18n.CookieLocaleResolver.LOCALE
        if (
            arr &&
            arr[0].trim() ==
            "org.springframework.web.servlet.i18n.CookieLocaleResolver.LOCALE"
        ) {
            cookieLang = arr[1];
            break;
        }
    }
    console.log(cookieLang,"cookieLang");
    return cookieLang;
};
// 获取浏览器默认语言
function getBrowserLang() {
    let browserLang = navigator.language
        ? navigator.language
        : navigator.browserLanguage;

    let defaultBrowserLang = "";
    if (
        browserLang.toLowerCase() === "us" ||
        browserLang.toLowerCase() === "en" ||
        browserLang.toLowerCase() === "en_us"
    ) {
        defaultBrowserLang = "en_US";
    } else if(
        browserLang.toLowerCase() === "nl" ||
        browserLang.toLowerCase() === "nl_nl"
    ) {
        defaultBrowserLang = "nl_NL";
    }else {
        defaultBrowserLang = browserLang;
    }
    return defaultBrowserLang;
};

function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie != '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = jQuery.trim(cookies[i]);
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) == (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
function showContainer() {
    $(".cookies-bottom-container").toggleClass("hide")
}

function showAcceptCookies() {
    showContainer();
}

function showCookieSettings() {
    $(".cookies_setting").toggleClass("v");
    $(".cookies").toggleClass("hide")
}


function agreeAll(){
    for(let key in CookieSet) {
        let item = CookieSet[key];
        item.status = true;
        item.version = cookieVersion[key]
        item.timestamp = (new Date()).getTime();
    }
    SaveCookieToStorage()
}

function saveCookieSettings() {
    $(".cookies_setting input").each(function (index, field) {
       var name = field.name;
       var value = field.checked;
       for(var k in CookieSet){
           if(name == k) {
               var item =  CookieSet[k]
               item.status = value;
               item.timestamp = (new Date()).getTime();
               item.version = cookieVersion[name];

           }

       }
        SaveCookieToStorage();

    })
}


function SaveCookieToStorage() {
    window.localStorage.setItem(storageKey, JSON.stringify(CookieSet))
    $(".cookies-bottom-container").addClass("hide");
    enableFunctionsFromCookie()
}


function enableFunctionsFromCookie() {
    if(CookieSet.analysis) {
        console.log("google analysis enable");
        //load google analysis
    }

}

