(function ($) {
    $(".orders").each(function () {
        var pictures = $(".order-form-product .color-item");
        pictures.each(function (index) {
            if(pictures.hasClass("red")){

            }
        });
      $("input[name=Color]").change(function () {
          var checked = $(this).is(":checked"),
              value  = $(this).val()
          if(checked){
             $(".order-form-product .color-item").addClass("hide");
             console.log("."+ value.toLowerCase())
             $(".order-form-product ." + value.toLowerCase()).removeClass("hide");
          }
      })
    })
    // alert("hello")
})(jQuery);

(function ($) {
    $(".ad_change").each(function () {
        // var pictures = $(".ad_img .cut-item");
        // pictures.each(function (index) {
        //     if(pictures.hasClass("red")){
        //
        //     }
        // });
        $("input[name=cut]").change(function () {
            var checked = $(this).is(":checked"),
                value  = $(this).val()
            if(checked){
                $(".ad_img .cut-item").addClass("hide");
                $(".ad_img ." + value.toLowerCase()).removeClass("hide");
                $(".radio-field-text").removeClass('radio-field-text-c');
                $("#radio-"+value).next('.radio-field-text').addClass('radio-field-text-c');
            }else {

            }
        })
    })
    // alert("hello")
})(jQuery);