(function ($) {
    var allDealers = [];
    var dealers = [];
    var map, markers = [];
    var search;
    var infoWindow = null;
    function setDealers() {

        var ciphertext = CryptoJS.enc.Hex.parse(dealerJson);
        var key = "a".repeat(16);
        key = CryptoJS.enc.Utf8.parse(key);
        var decrypted = CryptoJS.AES.decrypt({
            ciphertext: ciphertext
        }, key, {
            iv: key,
            mode: CryptoJS.mode.CBC,// CBC算法
            padding: CryptoJS.pad.Pkcs7 //使用pkcs7 进行padding 后端需要注意
        });
        var parsedDealers = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
        allDealers = dealers = parsedDealers;
        updateListView(parsedDealers);
        // updateMapView(parsedDealers); //需要等待google加载完成
    }

    function updateListView(dealersToUpdate) {
        dealers = [];

        // 删除之前的list view中的内容
        $('.dealer-list .row').empty();
        for (var i = 0; i < dealersToUpdate.length; i++) {
            var directionLink = 'https://www.google.com/maps?daddr=' + dealersToUpdate[i].address + ', ' + dealersToUpdate[i].city;
            var formattedAddress = dealersToUpdate[i].address.toProperCase() + ', ' + dealersToUpdate[i].city.toProperCase();
            var dealer = {
                lat: Number(dealersToUpdate[i].lat),
                long: Number(dealersToUpdate[i].long),
                name: dealersToUpdate[i].name,
                telephone: dealersToUpdate[i].telephone,
                email: dealersToUpdate[i].email,
                url: dealersToUpdate[i].url,
                address: dealersToUpdate[i].address,
                city: dealersToUpdate[i].city,
                county: dealersToUpdate[i].country,
                languages:dealersToUpdate[i].language_code,
                formattedAddress: formattedAddress,
                directionLink: directionLink,
            }
            dealers.push(dealer);
            // console.log(dealer)
            appendItem(dealer);
        }

        //向dealer list 页面插入dealer
        function appendItem(dealer) {
            var sed_email = "Send email";
            var goweb = "Go to website";
            var seedir = "See directions";
            switch(dealer.languages) {
                case "nl-NL":
                    sed_email = "Stuur e-mail";
                    goweb = "Ga naar website";
                    seedir = "Zie routebeschrijving";
                    break;
                case "":
                    break;
            }
            var itemWrap = $('<div class="col-md-6 col-lg-4"><div class="dealer-item"><div class="dealer-item-inner"></div></div></div>');
            var item = itemWrap.find(".dealer-item-inner");
            //add name
            item.append('<h3 class="dealer-name">' + dealer.name + '</h3>');

            if (dealer.telephone) {
                item.append(
                    '<p class="dealer-phone">' +
                    '<span class="item-prefix">Tel:</span>' +
                    '<span class="item-value">' + dealer.telephone + '</span>' +
                    '</p>'
                );
            }
            if (dealer.email) {
                item.append(
                    '<p class="dealer-email">' +
                    '<span class="item-prefix">Email:</span>' +
                    '<span class="item-value"><a href="mail://' + dealer.email + '">'+sed_email+'</a> </span>' +
                    '</p>'
                );
            }

            if (dealer.url && dealer.Url != "") {
                var url = dealer.url;

                if (url.indexOf('http') < 0 && url.indexOf('www') < 0) {
                    url = 'http://' + url;
                }

                item.append(
                    '<p class="dealer-website">' +
                    ' <span class="item-prefix">Website:</span>' +
                    '<span class="item-value">' +
                    '<a href="' + url + '" target="_blank">'+goweb+'</a>' +
                    '</span>' +
                    '</p>'
                );
            }
            if (dealer.formattedAddress) {
                item.append(
                    '<p class="dealer-address">' +
                    ' <span class="item-prefix">Address:</span>' +
                    '<span class="item-value">' + dealer.formattedAddress + '</span>' +
                    '</p>'
                );
            }

            if (dealer.formattedAddress && dealer.directionLink) {
                item.append(
                    '<p class="dealer-map-nav">' +
                    '<a href="' + dealer.directionLink + '" target="_blank">'+seedir+'</a>' +
                    '</p>'
                );
            }
            itemWrap.appendTo(".dealer-list .row")
        }

        /* for (var i = 0; i < dealers.length; i++) {
             var d = dealers[i];
             var lat = Number(d.lat);
             var lng = Number(d.long);

             if (lat > 0 && lng > 0) {
                 placeMarker({
                     lat: lat,
                     lng: lng
                 });
             }

         }

         if (markers.length > 0) {
             var bounds = new google.maps.LatLngBounds();

             for (var i = 0; i < markers.length; i++) {
                 //map 没有设置zoom,  而是将整个地图覆盖所有的markers
                 bounds.extend(markers[i].getPosition());
             }

             map.fitBounds(bounds, 100);
         }*/
    }

    function updateMapView(dealersToUpdate) {

        if (markers.length > 0) {
            for (var i = 0; i < markers.length; i++) {
                markers[i].setMap(null);
            }
        }

        markers = [];

        for (var i = 0; i < dealersToUpdate.length; i++) {
            var dealer = dealersToUpdate[i];
            if (dealer.lat && dealer.long && dealer.lat !== 0 && dealer.long !== 0) {
                placeMarker(
                    {
                        lat: Number(dealer.lat),
                        lng: Number(dealer.long)
                    },
                    dealer
                );
            }

        }

        if (markers.length > 0) {
            var bounds = new google.maps.LatLngBounds();

            for (var i = 0; i < markers.length; i++) {
                bounds.extend(markers[i].getPosition());
            }
            var center = bounds.getCenter();
            map.setCenter(center);
            // map.fitBounds(bounds, 100);  //会导致google map zoom zero
        }


    }
    function buildContentString(dealer){
        var contentString = '';
        var goweb = "Go to website";
        switch(dealer.languages) {
            case "nl-NL":
                goweb = "Ga naar website";
                break;
            case "":
                break;
        }
        contentString = '<div class="google-map-popup">' +
            '<h2 class="info-title">' + dealer.name + '</h2>' +
            '<div class="info-popup-body">' +
            '<p>Tel: ' +
            dealer.telephone +
            '<br/>' +
            '</p>';

        if (
            dealer.url &&
            dealer.url !== ''
        ) {

            var url = dealer.url;

            if (url.indexOf('http') < 0 && url.indexOf('www') < 0) {
                url = 'http://' + url;
            }

            contentString += '<p>' +
                '<a href="' + url + '" target="_blank">'+goweb+'</a>' +
                '</p>';
        }

        contentString += '</div>' +
            '</div>'

        return contentString;
    }
    function placeMarker(position, dealer) {
        var marker = new google.maps.Marker({
            position: position,
            map: map
        });

         google.maps.event.addListener(marker, 'click', function(){
             infoWindow.close();
             infoWindow.setContent(buildContentString(dealer));
             infoWindow.open(map, marker);
          });

        //    console.log(position)
        markers.push(marker);
        marker.setMap(map);

        //添加search 提醒
        /*   var autocomplete = new google.maps.places.Autocomplete(
            document.getElementById('search-input'),
            {
                types: ['geocode']
            }
        );

        autocomplete.setComponentRestrictions({
            country: [

            ]
        });

        autocomplete.addListener('place_changed', function () {
            var place = autocomplete.getPlace();
            //获取 input 地址的geo
            var lat = place.geometry['location'].lat();
            var long = place.geometry['location'].lng();
            console.log(lat, long)

        });*/
    }

    function initMap() {
        infoWindow = new google.maps.InfoWindow();
        map = new google.maps.Map(document.getElementById('dealer-google-map'), {
            //center: {lat: 59.913869, lng: 10.752245}, //  使用了bounds marker
            zoom: 3
        });
        updateMapView(dealers)
    }

    function LoadJs() {
        var googleMapJs = document.createElement("script");
        googleMapJs.src = "https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyCUfRThnphNumlLKkJMOxH2xhybO3Nsh1s&callback=initMap"
        googleMapJs.async = true;
        document.head.appendChild(googleMapJs)
        window.initMap = function () {
            //google js loaded and available
            initMap();
        }
    }


    $(document).ready(function () {
        /*  $.ajax("/dealers", {
              data: {
                  search: $("#search-input").val()
              },
              dataType: "json",
              success: function (result) {
                  dealers = result.data;
                  LoadJs(function () {
                      update()
                  })

              }
          })*/
        if(typeof dealerJson == "undefined") {
            return
        }
        setDealers();
        LoadJs();
        initSearch();


    });
    $("#navigator-map").click(function () {
        /* var center = map.getCenter();
         var zoom = map.getZoom();
        // console.log(zoom)
         google.maps.event.trigger(map, 'resize');
         map.setCenter(center);
         map.setZoom(3)*/


    })

    function initSearch() {
        //https://github.com/bvaughn/js-search
        search = new JsSearch.Search('name');
        //
        search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
        search.addIndex("name");
        search.addIndex("address");
        search.addIndex("city");
        search.addIndex("country");
        search.addDocuments(allDealers);
        console.log(search)

        var query = $("#search-input").val();
        if (search) {
            searchDealer(query)
        }
    }

    $("#search-input").keyup(function (e) {
        if (e.which == 13) {
            //enter
            var query = $("#search-input").val();
            if (search) {
                searchDealer(query)
            }
        }

    })
    $(".search-input-icon .icon").click(function (e) {
        e.preventDefault();
        var $this = $(this);
        // var form = $this.parents("form");
        //   form.submit()
        var query = $("#search-input").val();
        if (search) {
            searchDealer(query)
        }
    })

    function searchDealer(queryStr) {
        if (queryStr == "") {
            updateListView(allDealers)
            updateMapView(allDealers)
        } else if (search) {
            var result = search.search(queryStr)
            console.log(result)
            updateListView(result)
            updateMapView(result)
        }
    }
    $('#dealer-btn').click(function (e){
        e.preventDefault();
        // window.location.href = "/dealers?search=" + query;
        $('#dealer-form').submit();
    })
})(jQuery);


(function ($){

    let search_div = $("#dealer-search-wrap");
    let txt_ele = $("#dealer-search-wrap .text-input__wrap .text-input");
    let a_btn = $('#dealer-search-wrap .text-input__wrap #dealer-btn');
    search_div.hover(function (){
        $('#dealer-notice-field').removeClass("hide");
        // txt_ele.toggleClass("text-input-hover");
    });
    txt_ele.bind('click',function (e){
        e.preventDefault();
        if(!a_btn.hasClass('dealer-btn-click')){
            $('#dealer-btn img').attr('src','dist/images/icons/search-w.svg');
            a_btn.toggleClass('dealer-btn-click');
            txt_ele.toggleClass('text-input-hover');
        }

    })
})(jQuery);
