(function ($) {
    // console.log($('.page-container').find('.main'));
 // if($('.page-container').find('.main').hasClass('main')){
     $header = $("#headers");
     $headerWrap = $header.find(".header-wrap");
     var lastScrollTop = 0, delta = 0;
     if(window.innerWidth<=768){
         return;
     }
     $(window).scroll(function (e) {
         var nowScrollTop = $(this).scrollTop();
         if (Math.abs(lastScrollTop - nowScrollTop) >= delta) {
             if (nowScrollTop > lastScrollTop) {
                 // SCROLLING DOWN
              //   $header.height("")
                 if($headerWrap.hasClass("stuck")){
                     $headerWrap.removeClass("stuck");
                    // $headerWrap.addClass("stuckUp")
                 }
                 // console.log(nowScrollTop,'DOWN');
             } else {
                 // SCROLLING UP
                 // console.log(nowScrollTop,'UP');
                 if (nowScrollTop > delta) {
                   //  $header.height("")
                    $headerWrap.addClass("stuck");
                 } else {
                     $headerWrap.removeClass("stuck");
                 }
             }
             lastScrollTop = nowScrollTop;
         }
     });
 // }

})(jQuery);