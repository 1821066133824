(function ($) {
    let sideMenus = $(".menu-side__outer");
    //1. 导航中点击右上角图标
    let toggle = $("#toggle_menu");

    toggle.bind('click', function (event) {
        event.preventDefault();
        if(sideMenus.hasClass("hide")){
            sideMenus.removeClass("hide");   //防止在加载时，出现向右的动画

        }

        toggle.toggleClass("is-active");
        if (toggle.hasClass("is-active")) {
            sideMenus.fadeIn().addClass("is-active");
            document.body.style.overflow = 'hidden';
            //sideMenus.show().addClass("is-active");
        } else {
            sideMenus.fadeOut().removeClass("is-active");
            document.body.style.overflow = 'auto';
            //  sideMenus.hide().removeClass("is-active");
        }

    });

    //2. 点击空白处, sideMenus, 关闭窗口
    sideMenus.on('click', function (e) {
        let target = $(e.target);
        //选择国家语言时，关闭
        //如果是点击了menu-side__bar中的menu, 则不退出窗口

        if (target.parents(".menu-side__bar").length > 0 || target.hasClass("menu-side__bar")) {
            if(target.parents(".selectLang").length > 0){
            } else {
                return
            }

        }
        sideMenus.fadeOut().removeClass("is-active");
        toggle.removeClass("is-active");
        document.body.style.overflow = 'auto';
    })

    
    let lange =$('.sub-menu__item__language');
    lange.on('click',function (e){
        e.preventDefault();
        if($(".sub-menu__item__pick-language").css('display') =="block"){
            $(".sub-menu__item__language .icon--close").addClass("hidden");
            $(".sub-menu__item__language .icon--language").removeClass("hidden");
        }else {
            $(".sub-menu__item__language .icon--close").removeClass("hidden");
            $(".sub-menu__item__language .icon--language").addClass("hidden");
        }
        $(".sub-menu__item__pick-language").animate({"width": "toggle"});

    });

})(jQuery);

