var languages = [
    {country: "China", local: "中文", code: "zh"},
    {country: "Norway", local: "Bokmal", code:"no"},
    {country: "The Netherlands", local: "Deutsch", code:"nl"},
    {country: "International", local: "English", code: "en"},
];
(function ($) {
    $(".selectLang").click(function (e) {
        e.preventDefault();
        var items = [];
        for (var i = 0; i < languages.length; i++) {
            var item = languages[i];
            var itemEl = '<li data-code="' + item.code + '" data-country="'+item.country+'" class="list-item">' +
                '<a href="/switch_locale?locale='+item.code+'">' +
                '<div class="value">' + item.country + '</div>' +
                '<div class="label">' + item.local + '</div>' +
                '</a>' +
                '</li>'
            items.push(itemEl)
        }
        var $content = $('<div class="languages">' +
            '<section>' +
            '<div class="list"><h2>Select a region & language</h2>' +
            '<ul class="list-items">' + items.join("") + '</ul></div></section></div>');
        $content.Modal({
           // width: "1200px",
          //  height: "600px",
            isPage: true,
            blankClickClose: true,
            hideCloseBtn: true
        });

        $('.list-item').on("click", function () {
            var code = $(this).data("code"),
                country = $(this).data("country");
            var language = {
                country: country,
                code: code,
            }
            window.localStorage.setItem("language", JSON.stringify(language))
            $(".footer_locator .selectLang").find("span").text(country);
            $content.Modal("hide")
        })
    })
    //init
    var language = window.localStorage.getItem("language")

    if(language) {
        var obj = JSON.parse(language)
        $(".footer_locator .selectLang").find("span").text(obj.country);
    } else {
        $(".footer_locator .selectLang").find("span").text("International");
    }
})(jQuery);