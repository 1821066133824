(function ($) {

    $(".banner-slider .slide__content").slick({
        "arrows": false,
        "infinite": true,
        "swipeToSlide": true,
        draggable: false,
        dots: true,
    });
    var count = 4;
    if(window.innerWidth<479){
        count = 1;
        var height = $(".section-4 .banner-image").height();
        if(height > 0){
            console.log(  $(".banner-slider .slide__content slick-dots"))
            $(".section-4 .slide__content .slick-dots").css("top", (height + 30) + "px")
        }
    }

    if (window.innerWidth > 720){
         count = 4;
        if(window.innerWidth < 1200) {
            count = 3;
        }
        $(".focus-slider .focus-slider__content").slick({
            infinite: true,
            slidesToShow: count,
            slidesToScroll: count,
            draggable: false,
        });
    }

    $(".menu-slider .menu-slider_content").slick({
        infinite: true,
        slidesToShow: count,
        slidesToScroll: count,
        draggable: false,
    });
    if(window.innerWidth>480){
        $(".battery-slider .battery-slider_content").slick({
            infinite: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            draggable: false,
            // autoplay: true,
            autoplaySpeed: 2000,
        });
    }

    $(".desktop-carousel").each(function() {

        $(this).slick({
            "arrows": false,
            "infinite": true,
            "swipeToSlide": true,
            "prevArrow": "<button type=\"button\" class=\"slick-prev slick-arrow\"></button>",
            "nextArrow": "<button type=\"button\" class=\"slick-next slick-arrow\"></button>",
            "slidesToShow": 1,
            "variableWidth": false
        });

    });


    if(window.innerWidth>480) {
    //自定义nav bar
    $(".p-slider .slide__content").each(function (i, el) {
        //       return
        let $el = $(el);
        $el.slick({
            "arrows": false,
            "infinite": true,
            "swipeToSlide": true,
            //draggable: false,
            swipe: true,  // ipad上，不允许拖动，同上
            dots: true,
        });

        let _ = $el[0].slick; //.slick("getSlick");
        let navEls = $el.siblings(".slide__nav");

        if (navEls && navEls.length > 0) {
            var $navItems = navEls.find(".slide__nav-item")
            $el.slick('getSlick').$slider.on("swipe", function (e, slider, direction) {
                $navItems.removeClass("is-active");
                var index = slider.currentSlide;
                if ($navItems.length > index) {
                    $($navItems[index]).addClass("is-active")
                }

            })
            $navItems.each(function (index, element) {
                let $nav = $(element);

                $nav.on("click", function (e) {
                    e.preventDefault();
                    $nav.siblings().removeClass("is-active");
                    $nav.addClass("is-active");
                    //console.log(index)
                    //_.slideHandler(index, false, true) //不使用动画
                    $el.slick("getSlick").slideHandler(_.checkNavigable(index), false, false) //不使用动画
                })
            })
        }
    })
    /* let s = $(".slide__content").slick({
         "arrows": false,
         "infinite": true,
         "swipeToSlide": true,
         draggable: false,
         // dots: true,
         // waitForAnimate:false,
     });
     /!*  let b = $(".slide__nav").slick({
           arrows: false,
           asNavFor: '.slide__content',
           dots: true,
       })
       //或者s.slick("getSlick")
       console.log(s[0].slick)
       console.log(s[0].slick.getNavTarget()) //获取到$(".slide__content");*!/
     if (s.length > 0 && s[0].slick) {
         let _ = s[0].slick;
         $(".slide__nav-item").each(function (index, element) {
             let $el = $(element);

             $el.on("click", function (e) {
                 e.preventDefault();
                 $el.siblings().removeClass("is-active");
                 $el.addClass("is-active");
                 //console.log(index)
                 //_.slideHandler(index, false, true) //不使用动画
                 _.slideHandler(_.checkNavigable(index), false, false) //不使用动画
             })
         })

     }*/

        // button
        $(".pt-slider .slide__content").each(function (i, el) {
            let $el = $(el);
            let icon = "<button type=\"button\" class=\"slick-prev slick-arrow\"><img src='dist/images/icons/button-black-p.svg'></button>";
            let icon_ = "<button type=\"button\" class=\"slick-next slick-arrow\"><img src='dist/images/icons/button-black-n.svg'></button>";
            if ($el.hasClass('backgroud-color-white')) {
                icon = "<button type=\"button\" class=\"slick-prev slick-arrow backgroud-black-icon\"><img src='dist/images/icons/button-white-p.svg'></button>";
                icon_ = "<button type=\"button\" class=\"slick-next slick-arrow backgroud-black-icon\"><img src='dist/images/icons/button-white-n.svg'></button>";
            }
            $el.slick({
                "arrows": true,
                "infinite": true,
                "swipeToSlide": true,
                "prevArrow": icon,
                "nextArrow": icon_,
                //draggable: false,
                swipe: true,  // ipad上，不允许拖动，同上
                dots: true,
            });

            let _ = $el[0].slick; //.slick("getSlick");
            let navEls = $el.siblings(".slide__nav");

            if (navEls && navEls.length > 0) {
                var $navItems = navEls.find(".slide__nav-item")
                $el.slick('getSlick').$slider.on("swipe", function (e, slider, direction) {
                    $navItems.removeClass("is-active");
                    var index = slider.currentSlide;
                    if ($navItems.length > index) {
                        $($navItems[index]).addClass("is-active")
                    }
                });
                //button relevancy nav
                $el.slick('getSlick').$slider.on('afterChange', function (e, slider, direction) {
                    $navItems.removeClass("is-active");
                    var index = slider.currentSlide;
                    if ($navItems.length > index) {
                        $($navItems[index]).addClass("is-active")
                    }
                });


                $navItems.each(function (index, element) {
                    let $nav = $(element);
                    $nav.on("click", function (e) {
                        e.preventDefault();
                        $nav.siblings().removeClass("is-active");
                        $nav.addClass("is-active");
                        //console.log(index)
                        //_.slideHandler(index, false, true) //不使用动画
                        $el.slick("getSlick").slideHandler(_.checkNavigable(index), false, false) //不使用动画
                    })
                })
            }
        })
    }else {
        $(".p-mobile-slider .slide__content").each(function (i, el) {
            //       return
            let $el = $(el);
            $el.slick({
                "arrows": false,
                "infinite": true,
                "swipeToSlide": true,
                //draggable: false,
                swipe: true,  // ipad上，不允许拖动，同上
                dots: true,
            });

            let _ = $el[0].slick; //.slick("getSlick");
            let navEls = $el.siblings(".slide__nav");

            if (navEls && navEls.length > 0) {
                var $navItems = navEls.find(".slide__nav-item")
                $el.slick('getSlick').$slider.on("swipe", function (e, slider, direction) {
                    $navItems.removeClass("is-active");
                    var index = slider.currentSlide;
                    if ($navItems.length > index) {
                        $($navItems[index]).addClass("is-active")
                    }

                })
                $navItems.each(function (index, element) {
                    let $nav = $(element);

                    $nav.on("click", function (e) {
                        e.preventDefault();
                        $nav.siblings().removeClass("is-active");
                        $nav.addClass("is-active");
                        //console.log(index)
                        //_.slideHandler(index, false, true) //不使用动画
                        $el.slick("getSlick").slideHandler(_.checkNavigable(index), false, false) //不使用动画
                    })
                })
            }
        })
    }

})(jQuery);

